// Use this to write your custom SCSS
.offcanvas-start{
    width:100%;
}
.offcanvas{
   background-color: #1b2e5d;
}
.navbar-brand img {
    height: 50px;
}
.offcanvas-header.d-lg-none img{
    height:50px;
}
.modal-content{
    border-radius: none;
}
img.position-absolute.end-0.mt-5 {
    margin-right: 50px;
}
.bg-red-my{
    background: #e30613;
}
.bg-cyan-my{
    background: #008bd2;
}
.bg-grey-my{
    background: #e5e5e5;
}
/* footer */
footer.bg-dark .container {
    background-image: url(../../assets/img/NVKORT_Logo.png)!important;
    background-repeat: no-repeat;
    background-position: 0 30%;
}
/* modal menu */
.modal-header {
    height:90px;
}
.modal-menu-bg{
    background: #0184c8;
}
.modal-footer{
    height: 90px;
}
.mr-15{
    margin-right: 15px;
}
/* schedule */
a.btn.btn-outline-primary.rounded-0.class-button {
    overflow: hidden;
    position: relative;
    padding: 0 13rem 0 0;
    font-size: 2rem;
    width: 100%;
}
a.btn.btn-outline-primary.rounded-0.class-button i{
    position: absolute;
    right: 30px;
    font-size: 4rem;
    top: -50px;
    opacity: 0.7;
}
/* contacts */
form.contact-form.needs-validation {
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 60px;
}
/* z-index */
.z-index-1 {
  z-index: 10;
}
/* options */
.options-nav {
    position: fixed;
    top: 9rem;
    right: 0.75rem;
    border-radius: 1.25rem;
    z-index: 20;
}
.options-nav .dropdown-toggle {
    background: #ffffff;
    padding: 0.5rem;
    color: #404040;
    border-radius: 100%;
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}
.options-nav .dropdown-toggle {
    color: #404040;
    line-height: 1;
    font-size: 1.2rem;
}
.options-nav .dropdown-menu:not(.mega-menu):not(.image-dropdown) {
    min-width: 2.5rem;
    margin-top: 0.5rem;
    background: #fff;
    padding: 0.75rem 0 0.5rem 0;
    border-radius: 1.25rem;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}
.options-nav .dropdown-menu .dropdown-item {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 1.2rem;
    color: #404040;
}
.options-nav .dropdown-toggle::after {
    display: none;
}
.options-nav .dropdown-menu .dropdown-item:hover {
    color: #008bd2;
  }
.options-nav .dropdown-menu .dropdown-item {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 1.2rem;
    color: #404040;
}
.options-nav > .dropdown > .dropdown-menu.show > .dropdown-item:hover {
    background: none!important;
    color: #008bd2;
}
.options-nav .dropdown-item:active {
    background-color: none;
    color: #008bd2;
}
/* typography */
.text-black{
    color: #1e1e1e;
}
/* colors */
.bg-cyan{
    background:#008BD2;
}
.cyan-background{
    background: #008bd2;
    opacity: 0.8;
}
/* buttons */
.btn-outline-vacancy{
    border-color: #e30613;
    color: #e30613;
}
.btn-outline-vacancy:hover{
    color: #e30613;
}
.btn-outline-main{
    border-color:#008bd2;
    color: #008bd2;
}
.btn-outline-main:hover{
    background:#008bd2;
    color: #fff;
}
.btn-outline-second{
    background:#e30613;
    color: #fff;
}
.btn-outline-second:hover{
    background:#fff;
    color: #e30613;
}
/* feautures */
.bg-ort {
    color: #f8f8f8;
    font-family: "Gilroy";
    font-size: 500px;
    font-weight: 700;
    line-height: 175px;
    text-transform: uppercase;
    position: absolute;
    z-index: 2;
    left: 0;
    letter-spacing: 130px;
    top: 35%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
/* home news */
.blog-slider .swiper-slide:nth-child(6n), .blog-slider .swiper-slide:nth-child(6n+1) {
    width: 50%!important;
    max-width: 50%!important;
}

.blog-slider .swiper-slide {
    width: 25%!important;
    max-width: 25%!important;
    height:auto;
}

a.card-news.card-news--image:hover{
    color: #fff;
}
a.nav-link.text-uppercase.fs-28.fw-light.active.text-black{
    color:#000;
    border: none;
}
a.nav-link.text-uppercase.fs-28.fw-light.active.text-black:hover{
    color:#018bd3;
    border: none;
}
a.nav-link.text-uppercase.fs-28.fw-light{
    color: #aab0bc;
    border: none;
}
a.nav-link.text-uppercase.fs-28.fw-light:hover{
    color: #018bd3;
    border: none;
}
span.fw-bold.text-uppercase.fs-15:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -1px;
    display: block;
    height: 2px;
    width: calc(100% + 2px);
    color: inherit;
    background-color: currentColor;
    -webkit-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
}
a.text-button.text-black.position-relative:focus, a.text-button.text-black.position-relative:hover {
    outline: none;
    text-decoration: none;
}
.card-news {
    position: relative;
    overflow: hidden;
    display: block;
    color: #fff;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: color;
    -o-transition-property: color;
    transition-property: color;
}

.card-news__inner {
    position: relative;
    padding: 1rem 2rem 1rem 2rem;
    z-index: 2;
    height: 21.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.card-news__image {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.card-news__image:before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(30,30,30,0)),to(#1e1e1e));
    background-image: -o-linear-gradient(top,rgba(30,30,30,0),#1e1e1e);
    background-image: linear-gradient(180deg,rgba(30,30,30,0),#1e1e1e);
    opacity: .5;
}

.image {
    display: block;
    max-width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}

.image.is-lazy.is-loaded, .image.is-lazy.swiper-lazy-loaded {
    opacity: 1;
}
.card-news__bottom {
    margin-top: auto;
    max-width: 22.5rem;
}

.card-news__image .image, .card-news__image .image.is-loaded, .card-news__image .image.swiper-lazy-loaded {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-size: cover;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
}
.image.is-lazy {
    -webkit-transition: opacity .6s ease;
    -o-transition: opacity .6s ease;
    transition: opacity .6s ease;
    opacity: 0;
}

.slider-bottom-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.slider-bottom-line__btns {
    padding-top: 1rem;
    padding-right: 2.5rem;
}

.button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -1rem -1.5rem 0;
}

.button-group__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 1rem 1.5rem 0;
}

.slider-bottom-line__nav {
    margin-left: auto;
    margin-right: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.slider-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.slider-nav__counter {
    margin-right: 2rem;
}

.nav-counter {
    display: inline-block;
    font-size: 0;
}

.nav-counter__text--start {
    padding-right: 1.625rem;
}

.nav-counter__text--start {
    position: relative;
}

.nav-counter__text {
    display: inline-block;
    vertical-align: middle;
}

.text-menu {
    font-size: .875rem;
    line-height: 1.375rem;
    letter-spacing: .0625rem;
}

.nav-counter__text--end {
    color: #bcbcbc;
}

.nav-counter__text {
    display: inline-block;
    vertical-align: middle;
}

.slider-bottom-line__nav .nav-arrows {
    padding: 2px;
}

.nav-arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.nav-arrows__button:not(:last-child) {
    margin-right: 0.5rem;
}

.nav-arrows__button.disabled {
    border-color: rgba(30,30,30,.5);
    color: rgba(30,30,30,.5);
    pointer-events: none;
    cursor: default;
    background-color: rgba(0,0,0,0);
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.nav-arrows__button {
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: color,border-color,background-color,-webkit-box-shadow;
    transition-property: color,border-color,background-color,-webkit-box-shadow;
    -o-transition-property: color,border-color,background-color,box-shadow;
    transition-property: color,border-color,background-color,box-shadow;
    transition-property: color,border-color,background-color,box-shadow,-webkit-box-shadow;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    padding: 0;
    font-size: 0;
    line-height: 3rem;
    border-radius: 50%;
    border: 1px solid #1e1e1e;
    background-color: rgba(0,0,0,0);
    color: #1e1e1e;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 840px) {
    .bg-ort{
        display:none;
    }
    .img-inclussion{
        min-height: 300px;
    }
    .blog-slider .swiper-slide{
        width: 100%!important;
        max-width: 100%!important;
    }
    .blog-slider .swiper-slide:nth-child(6n), .blog-slider .swiper-slide:nth-child(6n+1){
        width: 100%!important;
        max-width: 100%!important;
    }
    #home-news .nav-item{
        margin-bottom: 0;
    }
    #home-news .nav-link{
        padding: 0;
    }

 }